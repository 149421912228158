<template>
  <div class='people'>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="学校人员" name="first">
        <personnel :activeName="activeName" />
      </el-tab-pane>
      <el-tab-pane label="供应商人员" name="second">
        <supplier :activeName="activeName" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Personnel from './personnel'
import Supplier from './supplierPer.vue'
// import Operation from './components/operation.vue'
export default {
  components: {
    Personnel,
    Supplier
    // Operation
  },
  data () {
    return {
      activeName: 'first'
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
