<template>
  <div class='personnel'>
    <div class="action">
      <div>
        <!-- <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" size="small">
          <el-option v-for="item in unitList" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>用户名：</span> -->
        <!-- <el-input clearable v-model="argumentsList.username" placeholder="请输入用户名" size="small"></el-input> -->
        <span>关键词：</span>
        <el-input clearable v-model="argumentsList.keyword" placeholder="单位名称/用户名/电话号码" size="small" @change="searchChange"></el-input>
        <span>用户级别：</span>
        <el-select clearable v-model="argumentsList.roleId" placeholder="请选择用户级别" size="small" @change="searchChange">
          <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <!-- <el-button type="primary" size="small" style="margin-left:10px">查 询</el-button> -->
      </div>
      <el-button type="primary" size="small" @click="$router.push('/addperson')">新 建</el-button>
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条
      </template>
    </el-alert>
    <el-table :data="tableData" stripe style="width: 100%" :cell-style="rowClass" :header-cell-style="headClass" size="mini" v-if="activeName === 'second'">
      <!-- <el-table-column prop="school" label="单位名称">
        <template slot-scope="{row}">
          {{ row.supplierName?row.supplierName:row.school }}
        </template>
      </el-table-column> -->
      <el-table-column prop="username" label="用户名">
      </el-table-column>
      <el-table-column prop="userType" label="用户类别" :filter-multiple="false" column-key="type">
        <template slot-scope="scope">
          <span>{{typeName(scope.row.userType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="realname" label="姓名">
      </el-table-column>
      <el-table-column prop="phone" label="电话">
      </el-table-column>
      <!-- <el-table-column prop="initPassword" label="初始密码">
          <template slot-scope="scope">
            <el-form-item v-if="scope.row.editShow" :prop="'tableData.' + scope.$index + '.initPassword'" :rules="rules.initPassword">
              <el-input v-model="scope.row.initPassword" size="small" />
            </el-form-item>
            <span v-else>{{scope.row.initPassword}}</span>
          </template>
        </el-table-column> -->
      <el-table-column prop="lastLogin" label="最近登录时间">
        <template slot-scope="{row}">
          {{row.lastLogin?row.lastLogin:'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="text" @click="edit(scope.row)" style="color:#1890ff;margin-right:10px;" slot="reference">修改资料</el-button> -->
          <el-popover placement="bottom-start" width="180" v-model="scope.row.visible">
            <p>您确认要删除该人员吗？</p>
            <div style="text-align: right; margin: 10px 0 0 0">
              <el-button size="mini" type="text" @click="scope.row.visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="ensureDelete(scope.row),scope.row.visible = false">确定</el-button>
            </div>
            <el-button type="text" slot="reference" style="color:red">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 人员修改弹框 -->
    <el-dialog title="修改资料" :visible.sync="personDialog" width="30%" :lock-scroll="false">
      <el-form :model="personForm" :rules="rules" label-width="120px" ref="personForm">
        <el-form-item prop="roleId" label="账号类型：">
          <el-select v-model="personForm.roleId" placeholder="请选择账号类型" size="small">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item prop="realname" label="姓名： ">
          <el-input v-model="userForm.realname" placeholder="请输入姓名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机： ">
          <el-input v-model="userForm.phone" placeholder="请输入您的手机号" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="username" label="用户名： ">
          <el-input v-model="userForm.username" placeholder="请输入用户名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="初始密码： ">
          <el-input v-model="userForm.password" placeholder="请输入初始密码" size="small">
          </el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="personDialog = false">取 消</el-button>
        <el-button type="primary" @click="personSumbit('personForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSupplier, deletePerson } from '@/api'
import { mapGetters } from 'vuex'
export default {
  props: {
    activeName: {
      type: String
    }
  },
  data () {
    return {
      argumentsList: {
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        keyword: '',
        roleId: null
      },
      tableData: [
      ],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      totalList: [],
      total: 0,
      personForm: {
        roleId: ''
      },
      rules: {
        roleId: [
          { required: true, message: '请选择用户类型', trigger: 'change' }
        ]
      },
      personDialog: false
      // 用户类别列表
      // typeList: []
    }
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['unitList', 'typeList', 'realname']),
    typeName () {
      return (val) => {
        // console.log('this.typeList :>> ', this.typeList);
        let arr = []
        let name = ''
        if (this.typeList.length > 0) {
          arr = this.typeList.filter(el => el.id === val)
        }
        if (arr.length > 0) {
          name = arr[0].name
        }
        return name
      }
    }
  },
  methods: {
    // 获取人员列表
    async init () {
      const res = await getSupplier(this.argumentsList)
      this.totalList = res
      this.total = res.length
      this.getList()
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    searchChange () {
      this.queryParams.pageNum = 1
      this.init()
    },
    // 点击编辑按钮
    edit (row) {
      console.log('row :>> ', row)
      this.personDialog = true
    },
    // 编辑人员确认
    personSumbit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除人员
    ensureDelete (val) {
      deletePerson({ id: val.id }).then(() => {
        this.$message.success('删除人员成功')
        this.$add(
          {
            userName: this.realname,
            module: '人员管理',
            content: `${this.realname}删除了名为 ${val.realname} 的人员`,
            type: 4,
            platform: 8,
            operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          }
        )
        this.init()
      })
      // console.log('val :>> ', val);
    },
    // 表格居中
    headClass () {
      return "text-align:center"
    },
    rowClass () {
      return "text-align:center"
    }
  }
};
</script>

<style scoped lang="scss">
.personnel {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      > span {
        margin-left: 10px;
      }
    }
    .el-input {
      width: 300px;
    }
  }
  ::v-deep .el-table {
    .el-form-item {
      // padding-bottom: 10px;
      margin-bottom: 0;
      .el-form-item__error {
        // bottom: -10px;
        // top: inherit;
        // left: 10px;
        position: relative;
      }
    }
    .el-input__inner {
      background: none;
    }
    .btns {
      display: inline-block;
      span {
        font-size: 12px;
        color: #1890ff;
        cursor: pointer;
        margin-right: 5px;
      }
    }
    .el-icon-arrow-down {
      color: #1890ff;
      // font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>
